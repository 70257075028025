import * as Ja from './ja/spec-create';
import * as En from './en/spec-create';
import * as Cn from './cn/spec-create';
import {getTextByLang} from "@/bootstraps/locale";
import {PackingUnitTypeEnum} from "@/entities/specs/spec-packing-form-entity";
import {help as LabelHelp} from "@/lang/help/ingredient-create";
import {exportAmountVisibility} from "./ja/spec-create";

export const help = {
  hasReference: getTextByLang({
    ja: Ja.hasReference,
    en: En.hasReference,
    cn: Cn.hasReference
  }),
  name: getTextByLang({
    ja: Ja.name,
    en: En.name,
    cn: Cn.name,
  }),
  foodType: getTextByLang({
    ja: Ja.foodType,
    en: En.foodType,
    cn: Cn.foodType,
  }),
  jicfs: getTextByLang({
    ja: Ja.jicfs,
    en: En.jicfs,
    cn: Cn.jicfs,
  }),
  intraCode: getTextByLang({
    ja: Ja.intraCode,
    en: En.intraCode,
    cn: Cn.intraCode,
  }),
  intraCodeForAcceptance: getTextByLang({
    ja: Ja.intraCodeForAcceptance,
    en: En.intraCodeForAcceptance,
    cn: Cn.intraCodeForAcceptance,
  }),
  releaseDate: getTextByLang({
    ja: Ja.releaseDate,
    en: En.releaseDate,
    cn: Cn.releaseDate,
  }),
  applyDate: getTextByLang({
    ja: Ja.applyDate,
    en: En.applyDate,
    cn: Cn.applyDate,
  }),
  aboutUrl: getTextByLang({
    ja: Ja.aboutUrl,
    en: En.aboutUrl,
    cn: Cn.aboutUrl,
  }),
  brandName: getTextByLang({
    ja: Ja.brandName,
    en: En.brandName,
    cn: Cn.brandName,
  },),
  brandType: getTextByLang( {
    ja: Ja.brandType,
    en: En.brandType,
    cn: Cn.brandType,
  }),
  expirationFormatType: getTextByLang( {
    ja: Ja.expirationFormatType,
    en: En.expirationFormatType,
    cn: Cn.expirationFormatType,
  }),
  packingFormUnit: getTextByLang( {
    ja: Ja.packingFormUnit,
    en: En.packingFormUnit,
    cn: Cn.packingFormUnit,
  }),
  packingFormTypes: {
    [PackingUnitTypeEnum.Piece]: getTextByLang( {
      ja: Ja.packingFormTypes[PackingUnitTypeEnum.Piece],
      en: En.packingFormTypes[PackingUnitTypeEnum.Piece],
      cn: Cn.packingFormTypes[PackingUnitTypeEnum.Piece],
    }),
    [PackingUnitTypeEnum.Ball]: getTextByLang( {
      ja: Ja.packingFormTypes[PackingUnitTypeEnum.Ball],
      en: En.packingFormTypes[PackingUnitTypeEnum.Ball],
      cn: Cn.packingFormTypes[PackingUnitTypeEnum.Ball],
    }),
    [PackingUnitTypeEnum.Pack]: getTextByLang( {
      ja: Ja.packingFormTypes[PackingUnitTypeEnum.Pack],
      en: En.packingFormTypes[PackingUnitTypeEnum.Pack],
      cn: Cn.packingFormTypes[PackingUnitTypeEnum.Pack],
    }),
    [PackingUnitTypeEnum.Case]: getTextByLang( {
      ja: Ja.packingFormTypes[PackingUnitTypeEnum.Case],
      en: En.packingFormTypes[PackingUnitTypeEnum.Case],
      cn: Cn.packingFormTypes[PackingUnitTypeEnum.Case],
    }),
    [PackingUnitTypeEnum.Kou]: getTextByLang( {
      ja: Ja.packingFormTypes[PackingUnitTypeEnum.Kou],
      en: En.packingFormTypes[PackingUnitTypeEnum.Kou],
      cn: Cn.packingFormTypes[PackingUnitTypeEnum.Kou],
    }),
  },
  packingFormQuantity: getTextByLang( {
    ja: Ja.packingFormQuantity,
    en: En.packingFormQuantity,
    cn: Cn.packingFormQuantity,
  }),
  isAlcohol: getTextByLang({
    ja: Ja.isAlcohol,
    en: En.isAlcohol,
    cn: Cn.isAlcohol,
  }),
  alcoholCode: getTextByLang({
    ja: Ja.alcoholCode,
    en: En.alcoholCode,
    cn: Cn.alcoholCode,
  }),
  seller: getTextByLang({
    ja: Ja.seller,
    en: En.seller,
    cn: Cn.seller,
  }),
  makerId: getTextByLang({
    ja: Ja.makerId,
    en: En.makerId,
    cn: Cn.makerId,
  }),
  ingredient: {
    similarNameAlert: getTextByLang({
      ja: Ja.similarNameAlert,
      en:En.similarNameAlert,
      cn:Cn.similarNameAlert
    }),
    allergen: getTextByLang({
      ja: Ja.ingredientAllergen,
    }),
    visible: getTextByLang({
      ja: Ja.ingredientVisible,
      en: En.ingredientVisible,
      cn: Cn.ingredientVisible,
    }),
    gmoType: LabelHelp.gmoType,
    gmoIp: getTextByLang({
      ja: Ja.ingredientGmoIp,
    }),
  },
  originCertification: getTextByLang({
    ja: Ja.originCertification,
    en: En.originCertification,
    cn: Cn.originCertification,
  }),
  nameAsIngredient: getTextByLang({
    ja: Ja.nameAsIngredient,
    en: En.nameAsIngredient,
    cn: Cn.nameAsIngredient,
  }),
  noteForIngredientList: getTextByLang({
    ja: Ja.noteForIngredientList,
    en: En.noteForIngredientList,
    cn: Cn.noteForIngredientList,
  }),
  labelCategoryType: getTextByLang({
    ja: Ja.labelCategoryType,
    en: En.labelCategoryType,
    cn: Cn.labelCategoryType,
  }),
  labelCategory: getTextByLang({
    ja: Ja.labelCategory,
    en: En.labelCategory,
    cn: Cn.labelCategory,
  }),
  labelAmountType: getTextByLang({
    ja: Ja.labelAmountType,
    en: En.labelAmountType,
    cn: Cn.labelAmountType,
  }),
  labelAmount: getTextByLang({
    ja: Ja.labelAmount,
    en: En.labelAmount,
    cn: Cn.labelAmount,
  }),
  labelExpirationType: getTextByLang({
    ja: Ja.labelExpirationType,
    en: En.labelExpirationType,
    cn: Cn.labelExpirationType,
  }),
  labelPreservationMethod: getTextByLang({
    ja: Ja.labelPreservationMethod,
    en: En.labelPreservationMethod,
    cn: Cn.labelPreservationMethod,
  }),
  labelMadeInCountry: getTextByLang({
    ja: Ja.labelMadeInCountry,
    en: En.labelMadeInCountry,
    cn: Cn.labelMadeInCountry,
  }),
  labelMadeInArea: getTextByLang({
    ja: Ja.labelMadeInArea,
    en: En.labelMadeInArea,
    cn: Cn.labelMadeInArea,
  }),
  labelPrecaution: getTextByLang({
    ja: Ja.labelPrecaution,
    en: En.labelPrecaution,
    cn: Cn.labelPrecaution,
  }),
  labelHowToCook: getTextByLang({
    ja: Ja.labelHowToCook,
    en: En.labelHowToCook,
    cn: Cn.labelHowToCook,
  }),
  labelUsage: getTextByLang({
    ja: Ja.labelUsage,
    en: En.labelUsage,
    cn: Cn.labelUsage,
  }),
  labelSanitizeMethod: getTextByLang({
    ja: Ja.labelSanitizeMethod,
    en: En.labelSanitizeMethod,
    cn: Cn.labelSanitizeMethod,
  }),
  labelHeatedBeforeFrozen: getTextByLang({
    ja: Ja.labelHeatedBeforeFrozen,
    en: En.labelHeatedBeforeFrozen,
    cn: Cn.labelHeatedBeforeFrozen,
  }),
  labelNeedHeating: getTextByLang({
    ja: Ja.labelNeedHeating,
    en: En.labelNeedHeating,
    cn: Cn.labelNeedHeating,
  }),
  labelStarchRatio: getTextByLang({
    ja: Ja.labelStarchRatio,
    en: En.labelStarchRatio,
    cn: Cn.labelStarchRatio,
  }),
  labelMilkNonfatSolidRatio: getTextByLang({
    ja: Ja.labelMilkNonfatSolidRatio,
    en: En.labelMilkNonfatSolidRatio,
    cn: Cn.labelMilkNonfatSolidRatio,
  }),
  labelMilkFatRatio: getTextByLang({
    ja: Ja.labelMilkFatRatio,
    // TODO: LANG
  }),
  labelOutsideText: getTextByLang({
    ja: Ja.labelOutsideText,
    // TODO: LANG
  }),
  labelCustomValues: getTextByLang({
    ja: Ja.labelCustomValues,
    // TODO: LANG
  }),
  extras: {
    // TODO: LANG
    precaution: getTextByLang({
      ja: Ja.extras.precaution,
    }),
    howToCook: getTextByLang({
      ja: Ja.extras.howToCook,
    }),
    usage: getTextByLang({
      ja: Ja.extras.usage,
    }),
    sanitizeMethod: getTextByLang({
      ja: Ja.extras.sanitizeMethod,
    }),
    heatedBeforeFrozen: getTextByLang({
      ja: Ja.extras.heatedBeforeFrozen,
    }),
    needHeating: getTextByLang({
      ja: Ja.extras.needHeating,
    }),
    starchRatio: getTextByLang({
      ja: Ja.extras.starchRatio,
    }),
    madeInCountry: getTextByLang({
      ja: Ja.extras.madeInCountry,
    }),
    madeInArea: getTextByLang({
      ja: Ja.extras.madeInArea,
    }),
  },
  nutritionUnit: getTextByLang({
    ja: Ja.nutritionUnit,
    en: En.nutritionUnit,
    cn: Cn.nutritionUnit,
  }),
  nutritionAmountPerUnit: getTextByLang({
    ja: Ja.nutritionAmountPerUnit,
    en: En.nutritionAmountPerUnit,
    cn: Cn.nutritionAmountPerUnit,
  }),
  nutritionGramPerMl: getTextByLang({
    ja: Ja.nutritionGramPerMl,
    en: En.nutritionGramPerMl,
    cn: Cn.nutritionGramPerMl,
  }),
  packageImage: getTextByLang({
    ja: Ja.packageImage,
    en: En.packageImage,
    cn: Cn.packageImage,
  }),
  packageGmoText: getTextByLang({
    ja: Ja.packageGmoText,
    en: En.packageGmoText,
    cn: Cn.packageGmoText,
  }),
  packageMadeInArea: getTextByLang({
    ja: Ja.packageMadeInArea,
    en: En.packageMadeInArea,
    cn: Cn.packageMadeInArea,
  }),
  isRiceTraceability: getTextByLang({
    ja: Ja.isRiceTraceability,
    en: En.isRiceTraceability,
    cn: Cn.isRiceTraceability,
  }),
  riceTraceabilityDisplayPlace: getTextByLang({
    ja: Ja.riceTraceabilityDisplayPlace,
    //TODO: LANG
    cn: Cn.riceTraceabilityDisplayPlace,
  }),
  manufactureProcessInputType: getTextByLang({
    ja: Ja.manufactureProcessInputType,
    //TODO: LANG
  }),
  manufacturingProcessText: getTextByLang({
    ja: Ja.manufacturingProcessText,
    en: En.manufacturingProcessText,
    cn: Cn.manufacturingProcessText,
  }),
  manufacturingProcessAttachment: getTextByLang({
    ja: Ja.manufacturingProcessAttachment,
    en: En.manufacturingProcessAttachment,
    cn: Cn.manufacturingProcessAttachment,
  }),
  manufacturingProcessManagementTarget: getTextByLang({
    ja: Ja.manufacturingProcessManagementTarget,
    en: En.manufacturingProcessManagementTarget,
    cn: Cn.manufacturingProcessManagementTarget,
  }),
  manufacturingProcessManagementPoint: getTextByLang({
    ja: Ja.manufacturingProcessManagementPoint,
    en: En.manufacturingProcessManagementPoint,
    cn: Cn.manufacturingProcessManagementPoint,
  }),
  manufacturingProcessStandard: getTextByLang({
    ja: Ja.manufacturingProcessStandard,
    en: En.manufacturingProcessStandard,
    cn: Cn.manufacturingProcessStandard,
  }),
  manufacturingProcessLawCriteria: getTextByLang({
    ja: Ja.manufacturingProcessLawCriteria,
    en: En.manufacturingProcessLawCriteria,
    cn: Cn.manufacturingProcessLawCriteria,
  }),
  manufacturingProcessMonitoringMethod: getTextByLang({
    ja: Ja.manufacturingProcessMonitoringMethod,
    en: En.manufacturingProcessMonitoringMethod,
    cn: Cn.manufacturingProcessMonitoringMethod,
  }),
  manufacturingProcessMeasureOnHazard: getTextByLang({
    ja: Ja.manufacturingProcessMeasureOnHazard,
    en: En.manufacturingProcessMeasureOnHazard,
    cn: Cn.manufacturingProcessMeasureOnHazard,
  }),
  manufacturingProcessHazardEffect: getTextByLang({
    ja: Ja.manufacturingProcessHazardEffect,
    en: En.manufacturingProcessHazardEffect,
    cn: Cn.manufacturingProcessHazardEffect,
  }),
  manufacturingProcessHazard: getTextByLang({
    ja: Ja.manufacturingProcessHazard,
    en: En.manufacturingProcessHazard,
    cn: Cn.manufacturingProcessHazard,
  }),
  manufacturingProcessValidation: getTextByLang({
    ja: Ja.manufacturingProcessValidation,
    en: En.manufacturingProcessValidation,
    cn: Cn.manufacturingProcessValidation,
  }),
  manufacturingProcessCorrectiveAction: getTextByLang({
    ja: Ja.manufacturingProcessCorrectiveAction,
    en: En.manufacturingProcessCorrectiveAction,
    cn: Cn.manufacturingProcessCorrectiveAction,
  }),
  detectionMethods: getTextByLang({
    ja: Ja.detectionMethods,
    // TODO: LANG
  }),
  metalDetectorFe: getTextByLang({
    ja: Ja.metalDetectorFe,
    en: En.metalDetectorFe,
    cn: Cn.metalDetectorFe,
  }),
  metalDetectorSus: getTextByLang({
    ja: Ja.metalDetectorSus,
    en: En.metalDetectorSus,
    cn: Cn.metalDetectorSus,
  }),
  weightCheckerMin: getTextByLang({
    ja: Ja.weightCheckerMin,
    en: En.weightCheckerMin,
    cn: Cn.weightCheckerMin,
  }),
  weightCheckerMax: getTextByLang({
    ja: Ja.weightCheckerMax,
    en: En.weightCheckerMax,
    cn: Cn.weightCheckerMax,
  }),
  contaminationCheckMethod: getTextByLang({
    ja: Ja.contaminationCheckMethod,
    en: En.contaminationCheckMethod,
    cn: Cn.contaminationCheckMethod,
  }),
  manufactureAttachments: getTextByLang({
    ja: Ja.manufactureAttachments,
    en: En.manufactureAttachments,
    cn: Cn.manufactureAttachments,
  }),
  hygieneCheckSheet: getTextByLang({
    ja: Ja.hygieneCheckSheet,
    en: En.hygieneCheckSheet,
    cn: Cn.hygieneCheckSheet,
  }),
  qualityEvidence: getTextByLang({
    ja: Ja.qualityEvidence,
    en: En.qualityEvidence,
    cn: Cn.qualityEvidence,
  }),
  contaminationAllergenIdListInSameFactory: getTextByLang({
    ja: Ja.contaminationAllergenIdListInSameFactory,
    en: En.contaminationAllergenIdListInSameFactory,
    cn: Cn.contaminationAllergenIdListInSameFactory,
  }),
  contaminationAllergenIdListPotentially: getTextByLang({
    ja: Ja.contaminationAllergenIdListPotentially,
    en: En.contaminationAllergenIdListPotentially,
    cn: Cn.contaminationAllergenIdListPotentially,
  }),
  contaminationPreventionMeasure: getTextByLang({
    ja: Ja.contaminationPreventionMeasure,
    en: En.contaminationPreventionMeasure,
    cn: Cn.contaminationPreventionMeasure,
  }),
  submissionSpec: getTextByLang({
    ja: Ja.submissionSpec,
    en: En.submissionSpec,
    cn: Cn.submissionSpec,
  }),
  submissionUnfilledRequiredFields: getTextByLang({
    ja: Ja.submissionUnfilledRequiredFields,
    // TODO
  }),
  requestCompanyId: getTextByLang({
    ja: Ja.requestCompanyId,
    en: En.requestCompanyId,
    cn: Cn.requestCompanyId,
  }),
  requestDueDate: getTextByLang({
    ja: Ja.requestDueDate,
    en: En.requestDueDate,
    cn: Cn.requestDueDate,
  }),
  requestItem: getTextByLang({
    ja: Ja.requestItem,
    en: En.requestItem,
    cn: Cn.requestItem,
  }),
  requestRequiredFields: getTextByLang({
    ja: Ja.requestRequiredFields,
    // TODO
  }),
  approvalOwnedSpec: getTextByLang({
    ja: Ja.approvalOwnedSpec,
    en: En.approvalOwnedSpec,
    cn: Cn.approvalOwnedSpec,
  }),
  approvalReceive: getTextByLang({
    ja: Ja.approvalReceive,
    en: En.approvalReceive,
    cn: Cn.approvalReceive,
  }),
  operatorIdForOwnedSpec: getTextByLang({
    ja: Ja.operatorIdForOwnedSpec,
    // TODO: LANG
  }),
  operatorIdForAcceptance: getTextByLang({
    ja: Ja.operatorIdForAcceptance,
    en: En.operatorIdForAcceptance,
    cn: Cn.operatorIdForAcceptance,
  }),
  merquriusNetId: getTextByLang({
    ja: Ja.merquriusNetId,
    // TODO: LANG
  }),
  originalSpecAttachment: getTextByLang({
    ja: Ja.originalSpecAttachment,
    en: En.originalSpecAttachment,
    cn: Cn.originalSpecAttachment,
  }),
  foodTypeImport: getTextByLang({
    ja: Ja.foodTypeImport,
    en: En.foodTypeImport,
    cn: Cn.foodTypeImport,
  }),
  infomartFile: getTextByLang({
    ja: Ja.infomartFile,
    en: En.infomartFile,
    cn: Cn.infomartFile,
  }),
  exportAmountVisibility: getTextByLang({
    ja: Ja.exportAmountVisibility,
  })
}
